export const Columns = [
    {
        name: 'studioTitle',
        title: 'Studio',
        hide: false,
    },
    {
        name: 'title',
    },
    {
        name: 'email',
    },
    {
        name: 'workEmail',
        title: 'Work Email',
    },
    {
        name: 'company',
    },
    {
        name: 'roleType',
        title: 'Role',
    },
    {
        name: 'accessType',
        title: 'Access',
    },
];