import { Component } from 'vue-property-decorator';
import { Columns } from './columns';
import Browser from '@/support/browser';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import ReportPageBase from '@/support/reportPageBase';
import Util from '@/support/utility';
import { IPermissionDto } from '@/interfaces';
import { InitialSort } from './initialSort';

@Component({
    filters: {
        capitalize(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        formatDate(value: string) {
            return Util.formatDate(value);
        }
    }
})
export default class PermissionsReportComponent extends ReportPageBase {
    options = {
        allData: Browser.getBoolParam('all', false) || Browser.getBoolParam('allData', false),
        limit: Browser.getIntParam('limit', 0),
        filter: Browser.getParam('filter', null),
    };

    selected: IPermissionDto[] = [];
    inherited: IPermissionDto[] = [];
    permissions: IPermissionDto[] = [];
    columns = Columns;

    $refs = {
        grid: null,
        deletePrompt: null,
    };

    created() {
        this.reportPageCreated('permissionsReport', InitialSort, true);
        this.gridColumns = this.columns;

        Debug.setDebugModule('App', this);
    }

    async mounted() {
        super.mounted();

        if (this.options.limit)
            this.state.limit = this.options.limit;

        if (this.options.allData)
            this.state.allData = true;
    }

    beforeDestroy() {
        super.beforeDestroy();
    }

    async getData() {
        return await this.getPermissions();
    }

    async getPermissions() {
        try {
            let studio = this.studioSelection.selected.studio;
            let response = await PlayerApi.permissionsList(studio, this.state.limit);

            this.inherited.splice(0, this.inherited.length);
            this.permissions.splice(0, this.permissions.length);

            response.permissions.forEach(permission => {
                let permObj = <IPermissionDto>permission;

                this.setPrivateMembers(permObj);

                if (studio && permObj.isAllStudio)
                    return;

                this.permissions.push(permObj);
            });

            Debug.log('getPermissions', studio, this.permissions.length, this.inherited.length, 'All Permissions');
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('Failed to get Permission list ' + message);
            return null;
        }
    }

    setPrivateMembers(permission: IPermissionDto) {

        permission.busy = false;

        if (permission.isAllStudio) {
            permission.studioTitle = Globals.AllStudiosTitle;
            return;
        }

        let studio = this.studios.find(item => item.studio == permission.studio);
        if (studio) {
            permission.studioTitle = studio.title;
        } else {
            permission.studioTitle = permission.studio;
        }
    }

    deleteSelected() {
        (<any>this.$refs.deletePrompt).show();
    }

    // ReSharper disable once InconsistentNaming
    async _deleteSelected() {
        try {
            this.busy = true;

            let selected = this.selected;

            let deletes = [];
            selected.forEach(permission => {
                deletes.push(PlayerApi.removePermission(permission.studio, permission));
            });

            let result = await Promise.all<SUR.PermissionDto>(deletes);

            let errors = [];
            let count = 0;
            result.forEach(permission => {
                let selectedItem = selected[count++];

                if (permission) {
                    let idx = this.permissions.indexOf(selectedItem);
                    if (idx != -1) {
                        this.permissions.splice(idx, 1);
                        Debug.log('deleteSelected DELETED', selectedItem, idx);
                        return;
                    }
                }

                errors.push(selectedItem);
                Debug.error('deleteSelected', selectedItem);
            });

            if (errors.length) {

                Util.showToast('Failed to delete permission(s)', true);
                return false;
            }

            Debug.log('deleteSelected SUCCESS', deletes, result);
            Util.showToast('Deleted permission(s)');

            return true;
        } catch (err) {
            Util.showToast('Failed to delete permission(s) ' + err, true);
            return false;
        } finally {
            this.busy = false;
            this.getPermissions();
        }
    }

    deletePromptClosed(result) {
        if (result)
            this._deleteSelected();
    }

    // base event handlers need to exist in outer class
    onGridDataChanged(count: number) {
        super.onGridDataChanged(count);
    }
}
