import { Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import LocalData from './localStorage';
import StudioPageBase from '@/support/studioPageBase';
import { IStudioDto, IGridColumn, ISortSpecifier } from '@/interfaces';
import PlayerUtil from './playerUtil';

const AllStudios: IStudioDto = {
    studio: null,
    title: Globals.AllStudiosTitle,
};

export default class ReportPageBase extends StudioPageBase {

    loading = true;
    loaded = false;
    busy = false;
    filterKey: string = null;
    href: string = null;
    dataCount = 0;
    exporting = false;

    gridColumns: IGridColumn[] = [];
    initialSort: ISortSpecifier[] = [];

    studioSelection = {
        show: false,
        selected: AllStudios,
        list: <IStudioDto[]>[AllStudios],
        state: {
            restore: false,
        }
    };

    state = {
        allData: false,
        limit: 1000,
    };

    get reportKey() {
        return this.baseKey;
    }

    get stateSaveKey() {
        return this.reportKey + '.state';
    }

    get studioSelectionSaveKey() {
        return this.reportKey + '.studioSelection.selected';
    }

    @Watch('state', { deep: true })
    onStateChanged(val: string, oldVal: string) {
        if (this.reportKey) {
            LocalData.save(this.stateSaveKey, this.state);
        }
    }

    @Watch('studioSelection.selected')
    async onStudioSelectionChanged(val: string, oldVal: string) {
        if (!this.loaded) return;

        this.loading = true;

        if (this.gridColumns.length) {
            this.toggleStudioSelection(this.gridColumns);
        }

        try {
            this.alias = this.studioSelection.selected.studio;
            await this.getData();
        } finally {
            this.loading = false;
        }

        if (this.studioSelection.state.restore && this.reportKey) {
            LocalData.save(this.studioSelectionSaveKey, this.studioSelection.selected);
        }

        this.refreshGrid();
    }

    get isValidStudio() {
        return this.studioSelection.selected && this.studioSelection.selected.title && this.studioSelection.selected.title != Globals.AllStudiosTitle;
    }

    reportPageCreated(key: string, initialSort: ISortSpecifier[], useExistingStudio = false, requireStudio = false) {
        super.created(key, useExistingStudio, requireStudio);

        this.initialSort.splice(0, this.initialSort.length, ...initialSort);
        this.studioSelection.state.restore = !!key;
    }

    mounted() {
        super.mounted();

        Debug.log('reportPageBase mounted', this.baseKey, this.gridColumns.length, this.initialSort.length);

        if (this.reportKey) {
            this.state = LocalData.get(this.stateSaveKey, this.state);
        }

        this.state.limit = this.state.limit || 1000;
    }

    protected async onLoaded(loaded: boolean) {
        super.onLoaded(loaded);

        if (!loaded) {
            PlayerUtil.redirectToError(this.$router);
            return;
        }

        let allStudios = !this.alias || !this.studio.studio;
        this.fillStudioSelection(!allStudios);

        if (this.gridColumns.length) {
            this.toggleStudioSelection(this.gridColumns);
        }

        await this.getData();

        this.loading = false;
        this.loaded = true;

        Debug.log('reportPageBase onLoaded', this.baseKey, this.studio.studio, this.studioSelection.selected.studio, this.gridColumns.length, this.initialSort.length);
    }

    // Must override
    async getData() {
        Debug.error('reportPage getData not overriden');
        return new Object();
    }

    fillStudioSelection(singleStudio: boolean) {
        this.studios.forEach(studio => {
            this.studioSelection.list.push({
                studio: studio.studio,
                title: studio.title,
            });
        });

        this.studioSelection.show = !singleStudio;

        if (singleStudio && this.studio.studio) {
            let studio = this.studioSelection.list.find(item => item.studio == this.studio.studio);
            if (studio) {
                this.studioSelection.selected = studio;
                return;
            }
        }

        this.restoreStudioSelectionState();
    }

    toggleStudioSelection(columns: Array<any>) {
        let idx = columns.findIndex(item => item.name == 'studio' || item.name == 'studioTitle');
        if (idx == -1)
            return;

        columns[idx].hide = this.isValidStudio;

        return true;
    }

    restoreStudioSelectionState() {
        if (!this.studioSelection.state.restore || !this.reportKey) return;

        let state = LocalData.get(this.studioSelectionSaveKey, null);
        if (state && state.studio) {
            let found = this.studioSelection.list.find(item => item.studio == state.studio);
            if (found) {
                this.studioSelection.selected = found;
            }
        }
    }

    // NOTE: Must override and call super.method
    onGridDataChanged(count: number) {
        this.dataCount = count;
        this.href = null;
    }

    exportToCsv() {
        if (this.href) return;

        let grid = <any>this.$refs.grid;
        if (!grid)
            return;

        this.exporting = true;

        let json = grid.filteredData;
        let fields = Object.keys(json[0]);
        let replacer = (key, value) => key == 'busy' || value === null ? '' : value;
        let csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(fields.join(','));

        let csvContent = "data:text/csv;charset=utf-8," + csv.join('\r\n');

        this.href = csvContent;

        setTimeout(() => {
            this.exporting = false;
        }, 1000);
    }

    /**
     * Should be overridden
     * */
    updateColumns() {

    }

    async refresh() {
        this.loading = true;
        this.updateColumns();

        let initialSort = this.initialSort.slice(0, this.initialSort.length);
        this.initialSort.splice(0, this.initialSort.length);
        initialSort.forEach(sort => {
            this.initialSort.push(sort);
        });

        await this.getData();

        this.loading = false;
        this.loaded = true;
    }

    refreshGrid() {
        let grid = <any>this.$refs.grid;
        if (grid)
            grid.refresh();
    }


}
